/** @jsx jsx */
import HomePageLayout from '../../components/HomePageLayout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import FAQSection from '../../components/FAQSection';

export default function FAQ({ location, data }) {
  return (
    <HomePageLayout
      location={location}
      lang="de"
      title="Häufige Fragen rund um den Immobilienverkauf"
      description="Sie haben die Fragen, wir haben die Antworten. Hier finden Sie die wichtigsten allgemeinen Antworten rund um den Hausverkauf."
      keywords={['Häufige Fragen']}
      image={data.hero.childImageSharp.fluid}
      header={{ attachment: 'default', appearance: 'default', fluid: true }}
    >
      <HeroSectionContainer
        fluid={true}
        orientation="southEast"
        variant="alpha"
        title="FAQs"
        image={data.hero}
      />

      <FAQSection
        background="muted"
        title="Häufige Fragen rund um den Immobilienverkauf"
        text={[
          {
            question: 'Was beeinflusst den Wert meiner Immobilie?',
            answer:
              'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam\n' +
              '            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam\n' +
              '            erat, sed diam voluptua.',
          },
          {
            question: 'Welche Steuern fallen beim Immobilienverkauf an?',
            answer:
              'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam\n' +
              '            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam\n' +
              '            erat, sed diam voluptua.',
          },
          {
            question: 'Wie funktioniert eine Immobilienbewertung?',
            answer: '',
          },
          {
            question: 'Wer führt die Immobilienbewertung durch?',
            answer: '',
          },
          {
            question:
              'Wer trägt die anfallenden Gebühren für die Kaufabwicklung?',
            answer: '',
          },
          {
            question: 'Welche Unterlagen benötige ich für einen Verkauf?',
            answer: '',
          },
          {
            question: 'Wie schnell kann ein Verkauf von Statten gehen?',
            answer: '',
          },
          {
            question:
              'Welche Kosten fallen für mich an, wenn ich die Leistungen von Werkauftmeinhaus.at beanspruchen möchte?',
            answer: '',
          },
          {
            question:
              'Wofür hafte ich, wenn ich meine Immobilie verkaufen möchte?',
            answer: '',
          },
          {
            question: 'Kann ich mir meinen Ansprechpartner selbst auswählen?',
            answer: '',
          },
          {
            question:
              'Wenn ich dem Angebot zustimme, wann bekomme ich mein Geld?',
            answer: '',
          },
          {
            question: 'Benötige ich für den Hausverkauf einen Energieausweis?',
            answer: '',
          },
          {
            question: 'Wie lange dauert es bis meine Immobilie verkauft ist?',
            answer: '',
          },
          {
            question:
              'Kann ich nach Verkauf noch in meinem Haus wohnen bleiben?',
            answer: '',
          },
          {
            question:
              'Was mache ich, wenn ich nicht alle für den Verkauf notwendigen Unterlagen habe?',
            answer: '',
          },
          {
            question:
              'Welche Kosten entstehen bei der Online Immobilienbewertung?',
            answer: '',
          },
          {
            question:
              'Woran kann ich erkennen, dass mein Kaufpreis fair berechnet wurde?',
            answer: '',
          },
          {
            question:
              'Welche Maßnahmen können dazu beitragen, dass sich mein Immobilienwert erhöht?',
            answer: '',
          },
          {
            question: 'Ist ein Rücktritt vom Kaufvertrag möglich?',
            answer: '',
          },
          {
            question: 'Woher weiß ich, was mein Haus wert ist?',
            answer: '',
          },
          {
            question: 'Wird mein Haus persönlich besichtigt?',
            answer: '',
          },
          {
            question: 'Wer kauft mein Haus?',
            answer: '',
          },
          {
            question:
              'Ist ein bestimmter Rechtsanwalt oder Notar vorgeschrieben oder kann ich den selbst wählen?',
            answer: '',
          },
          {
            question: 'Wie verbindlich ist die Werteinschätzung?',
            answer: '',
          },
          {
            question:
              'Meine Immobilie ist in einem sehr schlechten Zustand, wie hoch sind die Chancen für einen Verkauf?',
            answer: '',
          },
        ]}
      />
    </HomePageLayout>
  );
}

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "hero-immoankauf" }) {
      childImageSharp {
        fluid(maxWidth: 2340, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

/** @jsx jsx */
import { jsx } from 'theme-ui';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Icon from '@fify.at/gatsby-theme-base/src/components/Icon/Icon';
import Col from '@fify.at/gatsby-theme-base/src/components/Grid/Col';
import Row from '@fify.at/gatsby-theme-base/src/components/Grid/Row';
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';

export default function FAQSection({ title, header, text, ...props }) {
  return (
    <Section {...props}>
      <Heading
        sx={{
          color: 'primary',
          marginBottom: 7,
        }}
      >
        {title}
      </Heading>

      <Section background="#fff">
        {text &&
          text.map(textRow => (
            <Row sx={{ marginBottom: 3 }}>
              <Col>
                {textRow.question && (
                  <p sx={{ '&&&': { paddingBottom: 0, fontWeight: '800' } }}>
                    <Icon
                      sx={{ color: 'primary', marginRight: 2 }}
                      variant="fad fa-lg"
                      icon="fa-question-square"
                    />{' '}
                    {textRow.question}
                  </p>
                )}
                {textRow.answer && (
                  <p sx={{ '&&&': { marginLeft: 5 } }}>{textRow.answer}</p>
                )}
              </Col>
            </Row>
          ))}
      </Section>
    </Section>
  );
}
